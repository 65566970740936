import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

import Navbar from "../components/navbar";
import Footer from "../components/footer";

const SecondPage = () => (
  <div className="thankyou-page">
    <Navbar />
    <SEO title="Thank you" />
    <div className="thankyou">
      <h1 className="thankyou__heading">Thank you for contacting us!</h1>
      <h2 className="thankyou__subheading">We will get back to you as soon as possible.</h2>
    </div>
    <Footer />
  </div>
)

export default SecondPage
