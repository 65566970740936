import React from 'react';
import { Link } from "gatsby"

import "../styles/styles.scss";

import logoWhite from "../assets/datahelm-logo-white.png";

const Footer = () => (
  <footer className="footer">
    <div className="footer-logo">
      <img src={logoWhite} alt=""/>
    </div>
  </footer>
)

export default Footer;