import { Link } from "gatsby"
import React, { useState } from "react"

import "../styles/styles.scss";

import logo from "../assets/datahelm-logo.png";
import menuIcon from "../assets/mobile-menu.svg";
import navBlob from "../assets/navbar-blob.svg";

const Navbar = () => {
  const [navActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
    if (!navActive) {
      document.body.classList.add('nav-open');
    } else {
      document.body.classList.remove('nav-open');
    }
  }

  return (
    <header className="navbar">
      <div className="navbar-left">
        <Link to="/">
          <img className="navbar-logo" src={logo} alt="logo" />
        </Link>
        <ul className="navbar-links">
          <li>
            <a className="navbar-link" href="/#product">Product</a>
          </li>
          <li>
            <a className="navbar-link" href="/#about">About</a>
          </li>
          <li>
            <a className="navbar-link" href="/#contact">Contact</a>
          </li>
        </ul>
      </div>
      <div className="navbar-right">
        <Link className="navbar-get-started" to="/">Get started</Link>
        {/* eslint-disable-next-line */}
        <img className="navbar-menu-icon" src={menuIcon} onClick={toggleNav} onKeyDown={toggleNav} alt="menu-icon"></img>
        <img className="navbar-blob" src={navBlob} alt=""></img>
      </div>
      <div className={!!navActive ? "mobile-menu mobile-menu--active" : "mobile-menu mobile-menu--inactive"}>
        <ul className="mobile-menu-list">
          <li className="mobile-menu-list__item">
            <a href="/#product" onClick={toggleNav}>Product</a>
          </li>
          <li className="mobile-menu-list__item">
            <a href="/#about" onClick={toggleNav}>About</a>
          </li>
          <li className="mobile-menu-list__item">
            <a href="/#contact" onClick={toggleNav}>Contact</a>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Navbar;
